import React from "react";
import { Link } from "react-router-dom";
import { KiteIcon } from "@kite/react-kite";
import { Typography, Grid } from "@material-ui/core";
import { navStyles } from "../styles/miscStyles";
import { useLocation } from "react-router-dom";
import { SINGLESIGNON } from "../utils/types";

export default function BackNavigation() {
  const isLogin = useLocation().pathname === "/login";
  const isSignup = useLocation().pathname === "/signup";
  const isContactUs = useLocation().pathname === "/contact-us";
  const isForgotPassword = useLocation().pathname === "/forgot-password";
  const isForgotUsername = useLocation().pathname === "/forgot-username";
  const userHasAuthCode = localStorage.getItem("code");
  const hasUser = document.cookie.includes("user");
  const classes = navStyles();

  const showBackNavigation =
    isForgotUsername ||
    isSignup ||
    isContactUs ||
    isForgotPassword ||
    (!isLogin && !userHasAuthCode && !hasUser);

  const logoutIfSignupPage = async () => {
    if (isSignup) {
      Object.keys(localStorage).forEach((key) => {
        let allowed = ["callbackUrl", "clientId"];
        if (!allowed.includes(key)) {
          localStorage.removeItem(key);
        }
      });
    }
    else return;
  };

  return (
    <div className={classes.root}>
      {showBackNavigation && (
        <div className={classes.outlinedContainer} onClick={logoutIfSignupPage}>
          <Link to="/login" className={classes.link}>
            <Grid
              container
              direction="row"
              className={classes.backNavigationContainer}
            >
              <KiteIcon
                ariaLabel=""
                badge=""
                className=""
                fill="#0077AE"
                icon="ki-arrow-left"
                size="13px"
              />
              <Typography className={classes.indicator}>
                {SINGLESIGNON.backToSignIn}
              </Typography>
            </Grid>
          </Link>
        </div>
      )}
    </div>
  );
}
